import { useContext } from '@nuxtjs/composition-api'

import {
  HomepageRowsVideoBandBlockVideoConnection,
  GfContentSlotComponentsComponentsReferenceBlockEntityConnection,
  ContentImage,
  GfContentSlotComponentsComponentsMenuBlockItemsUrlConnection,
  NavigationItemsNavigationLinkConnection,
  GfContentSlotComponentsComponentsCarouselBlockItemsImageConnection,
  GfContentSlotComponentsComponentsCarouselBlockItemsUrlConnection,
  HomepageRowsHeroBandBlockBackgroundImageConnection,
  HomepageRowsVideoBandBlockHeaderHeadImageConnection,
  HomepageRowsVideoBandBlockFooterImageConnection,
} from '../types'

import { ReferenceItem } from '~/types/static-content/staticContentTypes'

const parseUrlConnectionItem = (
  urlConnection?:
    | GfContentSlotComponentsComponentsMenuBlockItemsUrlConnection
    | GfContentSlotComponentsComponentsCarouselBlockItemsUrlConnection
    | null
) => {
  if (!urlConnection) return null

  const node = urlConnection?.edges![0]?.node
  const link = node?.link_v2
  const target = node?.open_in_new_window ? '_blank' : '_self'

  return link
    ? {
        title: link.title,
        link: {
          url: link.href,
        },
        target,
      }
    : null
}

const parseNavigationLinkConnectionItem = (
  navigationLinkConnection?: NavigationItemsNavigationLinkConnection | null
) => {
  if (!navigationLinkConnection) return null

  const node = navigationLinkConnection?.edges![0]?.node
  const link = node?.link_v2
  const target = node?.open_in_new_window ? '_blank' : '_self'

  return link
    ? {
        title: link.title,
        link: {
          url: link.href,
        },
        target,
      }
    : null
}

const parseImageConnectionItem = (
  imageConnection?:
    | GfContentSlotComponentsComponentsCarouselBlockItemsImageConnection
    | HomepageRowsHeroBandBlockBackgroundImageConnection
    | HomepageRowsVideoBandBlockHeaderHeadImageConnection
    | HomepageRowsVideoBandBlockFooterImageConnection
    | null
) => {
  if (!imageConnection) return null

  const ctx = useContext()
  const img = imageConnection?.edges![0]?.node as ContentImage

  if (!img) return null

  return {
    ...img,
    ...(img.media_server_id && {
      media_server_id: ctx.$img(`${img.media_server_id}`, null, { provider: 'ms' }),
    }),
    ...(img.cloudflare_id && {
      cloudflare_id: ctx.$img(img.cloudflare_id, null, { provider: 'mc' }) + '/2X',
    }),
  }
}

const parseReferenceItem = (referenceItem: GfContentSlotComponentsComponentsReferenceBlockEntityConnection) => {
  if (!referenceItem) return null

  const reference = referenceItem?.edges![0]?.node
  if (!reference) return null

  return reference as ReferenceItem
}

const parseVideoConnection = (videoConnection: HomepageRowsVideoBandBlockVideoConnection) => {
  if (!videoConnection) return null

  const node = videoConnection?.edges![0]?.node
  const videoId = node?.video_id

  return videoId ? node : null
}

export {
  parseUrlConnectionItem,
  parseNavigationLinkConnectionItem,
  parseReferenceItem,
  parseImageConnectionItem,
  parseVideoConnection,
}
