









































import { defineComponent, useContext } from '@nuxtjs/composition-api'

// Components
import ContentVideo from '~/components/feature/static-content/components/ContentVideo.vue'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import ContentImage from '~/components/feature/static-content/components/ContentImage.vue'

// Content page parsers
import { parseImageConnectionItem, parseVideoConnection } from '@/modules/contentstack/parser/contentPageParser'

// Common
import { isEmptyJsonRteField } from '@/common/utils/static-content/cms'

// Types
import { HomepageRowsVideoBand } from '~/modules/contentstack/types'
import { MappedHomepageRowsVideoBand } from '~/types/cms/homepage'

export default defineComponent({
  name: 'HomepageVideo',
  components: {
    ContentVideo,
    ContentImage,
    JsonRteField,
  },
  props: {
    section: {
      type: Object as () => HomepageRowsVideoBand,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const getBackgroundStyles = (cloudflareId: string | null | undefined) => {
      if (cloudflareId === 'None' || !cloudflareId) return {}

      return {
        backgroundImage: `url(${ctx.$img(cloudflareId, null, { provider: 'mc' }) + '/2X'})`,
        backgroundSize: 'cover',
        paddingTop: `3rem`,
      }
    }

    const showFooterText = () => {
      return (
        props.section?.video_band?.footer &&
        props.section.video_band.footer.text &&
        !isEmptyJsonRteField(props.section.video_band.footer.text.json)
      )
    }

    const data = {
      ...props.section,
      video: props.section?.video_band?.videoConnection
        ? parseVideoConnection(props.section.video_band.videoConnection)
        : null,
      headerImage: props.section.video_band?.header?.head_imageConnection
        ? parseImageConnectionItem(props.section.video_band.header.head_imageConnection)
        : null,
      footerImage: props.section.video_band?.footer?.imageConnection
        ? parseImageConnectionItem(props.section.video_band.footer.imageConnection)
        : null,
    } as MappedHomepageRowsVideoBand

    return { getBackgroundStyles, showFooterText, data }
  },
})
