

































import { defineComponent } from '@nuxtjs/composition-api'

// Components
import ContentImage from '@/components/feature/static-content/components/ContentImage.vue'
import ContentVideo from '@/components/feature/static-content/components/ContentVideo.vue'

// Types
import { HomepageRowsTiles } from '~/modules/contentstack/types'
import { MappedHomepageRowsTiles } from '~/types/cms/homepage'

// Content page parsers
import {
  parseImageConnectionItem,
  parseVideoConnection,
  parseNavigationLinkConnectionItem,
} from '@/modules/contentstack/parser/contentPageParser'

export default defineComponent({
  name: 'HomepageRowTiles',
  components: {
    ContentImage,
    ContentVideo,
  },
  props: {
    section: {
      type: Object as () => HomepageRowsTiles,
      required: true,
    },
  },
  setup(props) {
    const data = {
      ...props.section.tiles,
      tile: props?.section?.tiles?.tile?.map((element: any) => {
        element.tile_thumbnail_image = parseImageConnectionItem(element.tile_thumbnail_imageConnection)
        element.tile_video = parseVideoConnection(element.tile_videoConnection)
        element.tile_link = parseNavigationLinkConnectionItem(element.tile_hyperlink.referenceConnection)
        return element
      }),
    } as MappedHomepageRowsTiles
    return { data }
  },
})
